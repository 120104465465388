<div>
    <div class="mt-1">&nbsp;</div>
    <tabset>
        <tab heading="{{ 'ForgotCredentials.ForgotPassword'|translate }}" id="forgotPasswordTab" index="ForgotPassword"
            [active]="!activeTab" >
            <form [formGroup]="forgetPasswordForm" class="form-floating" (ngSubmit)="passwordFormSubmit()"
                #form="ngForm" autocomplete="off">

                <div class="form-floating mt-5">
                    <input autofocus id="email" (keyup)="enableOrDisableComponent('email')" type="email" class="form-control" formControlName="email"
                        placeholder="Email">
                    <label for="email">{{'ForgotCredentials.Email' | translate}} </label>
                    <div class="eyetriangle">
                        <span class="px-2">
                            <i>
                                <fa-icon class="red-text" [icon]="WarningIcon"
                                    *ngIf="(form.errors?.required || form.errors?.email) && form.submitted">
                                </fa-icon>
                            </i>
                        </span>
                    </div>
                </div>
                <div class="my-1 text-center medium">
                    {{'ForgotCredentials.OR' | translate}}
                </div>
                <div class="form-floating my-1">
                    <input id="userId" maxlength="50" type="text" (keyup) = "enableOrDisableComponent('userId')" class="form-control" formControlName="userId"
                        placeholder="{{'Username' | translate}}">
                    <label for="userId">{{'ForgotCredentials.UserName' | translate}}</label>
                    <div class="eyetriangle">
                        <span class="px-2">
                            <i>
                                <fa-icon class="red-text" [icon]="WarningIcon"
                                    *ngIf="(form.errors?.required || form.errors?.required) && form.submitted"></fa-icon>
                            </i>
                        </span>
                    </div>
                </div>

                <div *ngIf="(form.dirty || form.touched) && form.errors && form.submitted"
                    class="mt-0 red-text small position-absolute">
                    <span *ngIf="form.errors.required">{{'ForgotCredentials.UsernameEmailRequiredMessage' |
                        translate}}</span>
                    <span *ngIf="form.errors.email">{{'ForgotCredentials.ValidEmailRequiredMessage' | translate}}</span>
                </div>
                <div class="mt-2 ">
                    <button type="button" class="btn btn-secondary btn-half-block btn-half-block-secondary "
                        (click)="cancel()">
                        {{'ForgotCredentials.Cancel' | translate}}
                    </button>
                    <button type="submit" class="btn btn-primary btn-half-block">
                        {{'Submit' | translate}}
                    </button>
                </div>
            </form>
        </tab>
        <tab heading="{{ 'ForgotUsername'|translate }}" id="forgotUsernameTab" index="ForgotPassword"
            [active]="activeTab">
            <form [formGroup]="forgetUserIdForm" class="form-floating" (ngSubmit)="userIdFormSubmit()"
                #userIdform="ngForm">
                <div class="my-2">
                    <div class="mt-1">&nbsp;</div>
                    <div class="form-floating mt-3">
                        <input autofocus id="email" type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': userIdform.submitted
                && userIdFormControls.email.invalid , 'is-valid': userIdFormControls.email.valid }" placeholder="Email"
                [attr.aria-invalid] = "userIdform.submitted && userIdFormControls.email.invalid ">
                        <label for="email"> {{'ForgotCredentials.Email' | translate}} </label>
                        <div class="invalid-feedback small position-absolute">
                            <div *ngIf="userIdFormControls.email.errors?.required" aria-invalid="true" role="alert">
                                {{'ForgotCredentials.EmailRequiredMessage' | translate}}
                            </div>
                            <div *ngIf="userIdFormControls.email.errors?.email" aria-invalid="true" role="alert">
                                {{'ForgotCredentials.ValidEmailRequiredMessage' | translate}}
                            </div>
                        </div>
                        <div class="eyetriangle">
                            <span class="px-2">
                                <i>
                                    <fa-icon class="red-text" [icon]="WarningIcon"
                                        *ngIf="userIdFormControls.email.invalid && userIdform.submitted">
                                    </fa-icon>
                                </i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mt-5 mb-3">
                    <button type="cancel" class="btn btn-secondary btn-half-block-secondary" (click)="cancel()">
                        {{'ForgotCredentials.Cancel' | translate}}
                    </button>
                    <button type="submit" class="btn btn-primary btn-half-block">
                        {{'Submit' | translate}}
                    </button>
                </div>
            </form>
        </tab>
    </tabset>
    <ng-template #infoTemplate>
        <div class="modal-body pb-0 small">
            <div class="d-flex align-items-center mb-2">
                <span class="modal-message-icon">                    
                    <span *ngIf = "isSuccess">
                        <fa-icon [icon]="Tick"></fa-icon>
                    </span>
                    <span *ngIf = "!isSuccess">
                        <fa-icon class = "red-text"  [icon]="WarningIcon"></fa-icon>
                    </span>
                </span>
                <span class="large px-2">{{messageHeader | translate}}</span>
            </div>
            <p class="mt-2">{{messageText | translate}}

        </div>
        <div class="modal-footer pt-0 small">
            <button type="button" class="btn btn-primary" (click)="alertOk()">{{'OK' | translate}}</button>
        </div>
    </ng-template>
</div>
