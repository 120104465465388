import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forgetCredentialValidator } from './forget-credential.validator';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LayoutViewModel } from '../models/layout-view.model';
import { AppService } from '../app.service';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ForgetCredentialService } from './forget-credential.service';
import { RecoverPasswordModel } from '../models/recover-password.model';
import { RecoverPasswordResultModel } from '../models/recover-password-result.model';
import { RecoverUsernamesModel } from '../models/recover-usernames.model';
import { RecoverUsernamesResult } from '../models/recover-usernames-result.model';

@Component({
  selector: 'app-forget-credential',
  templateUrl: './forget-credential.component.html',
  styleUrls: ['./forget-credential.component.scss']
})
export class ForgetCredentialComponent implements OnInit {
  [x: string]: any;
  layoutView: LayoutViewModel;
  Tick = faCheckCircle;
  WarningIcon = faExclamationTriangle;
  messageHeader = 'ForgotCredentials.VerifyEmail';
  messageText = 'ForgotCredentials.Message';
  recoverResult: RecoverPasswordResultModel | RecoverUsernamesResult;
  recovertType: string;
  isSuccess: boolean;
  @ViewChild('infoTemplate', { static: true })
  public infoTemplateRef: TemplateRef<any>;
  activeTab: boolean = false;
  forgetPasswordForm = new FormGroup({
    email: new FormControl(''),
    userId: new FormControl(''),
  }, { validators: forgetCredentialValidator });

  forgetUserIdForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  okModalRef?: BsModalRef;

  constructor(private appService: AppService, private router: Router, private modalService: BsModalService, private translate: TranslateService, private titleService: Title, private forgetCredentialService: ForgetCredentialService) {
  }

  ngOnInit() {
    this.layoutView = this.appService.layoutView;
    this.translate.get("/ForgetCredential").subscribe(res => {
      this.titleService.setTitle("GEP | " + res);
    });
    this.appService.titleKey = "/ForgetCredential";
    this.activeTab = this.appService.forgotPasswordTab;
  }

  get passwordFormControls() { return this.forgetPasswordForm.controls; }
  get userIdFormControls() { return this.forgetUserIdForm.controls; }

  passwordFormSubmit(): void {
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    const recoverPasswordrequest: RecoverPasswordModel = {
      email: this.forgetPasswordForm.value.email,
      userName: this.forgetPasswordForm.value.userId,
      cultureCode: this.appService.cultureCode,
      returnUrl: this.appService.returnUrl
    }    
    this.forgetCredentialService.recoverPassword(recoverPasswordrequest).subscribe(recoverPasswordResult => {
      if (recoverPasswordResult) {
        this.recoverResult = recoverPasswordResult;
        this.recovertType = "recoverPassword";
        if (recoverPasswordResult.isUserSso) {
          this.showMessage('ForgotCredentials.Error','ForgotCredentials.SsoMessage', true);
          return;
        } else if (recoverPasswordResult.isSuccess) {          
          this.showMessage('ForgotCredentials.VerifyEmail','ForgotCredentials.PasswordSuccessMessage', true);
        } else if (recoverPasswordResult.isEmailFailure) {          
          this.showMessage('ForgotCredentials.Error','ForgotCredentials.EmailSendFailureMessage',false);
        }  else if (recoverPasswordResult.isInactive) {          
          this.showMessage('ForgotCredentials.Error','ForgotCredentials.InActiveMessage', false);
        }
      } else {
        this.showMessage('ForgotCredentials.Error','ForgotCredentials.EmailSendFailureMessage', false);
        return;
      }
    });
  }
  showMessage(messageHeader: string, messageText: string, isSuccess: boolean): void{
    this.messageHeader = messageHeader;
    this.messageText = messageText;
    this.isSuccess = isSuccess;
    this.okModalRef = this.modalService.show(this.infoTemplateRef, { class: 'modal-dialog-centered' });
  }
  cancel(): void {
    this.router.navigate(['/Logon'], { queryParams: { ReturnUrl: this.appService.returnUrl } });
  }
  alertOk(): void {
    if (this.okModalRef) {
      this.okModalRef.hide();
    }
    if (this.recovertType == "recoverUsername"){
        const result = <RecoverUsernamesResult>this.recoverResult
        if (result.isSuccess || result.isUserLocked || result.isUserSso){
          this.router.navigate(['/Login'], { queryParams: { ReturnUrl: this.appService.returnUrl } });      
        }   
    } else {
      const result = <RecoverPasswordResultModel>this.recoverResult
      if (result.isUserSso || result.isSuccess || result.isInactive){
        this.router.navigate(['/Login'], { queryParams: { ReturnUrl: this.appService.returnUrl } });      
      }   
    }        
  }
  userIdFormSubmit(): void {
    if (this.forgetUserIdForm.invalid) {
      return;
    }
    const recoverUsernamesModel: RecoverUsernamesModel = {
      email: this.forgetUserIdForm.value.email,      
      cultureCode: this.appService.cultureCode
    }    
    this.forgetCredentialService.recoverUsernames(recoverUsernamesModel).subscribe(recoverUsernamesResult => {
      if (recoverUsernamesResult) {
        this.recovertType = "recoverUsername";
        this.recoverResult = recoverUsernamesResult;        
        if (recoverUsernamesResult.isUserSso) {
          this.showMessage('ForgotCredentials.Error','ForgotCredentials.SsoMessage', true);
          return;
        } else if (recoverUsernamesResult.isSuccess) {          
          this.showMessage('ForgotCredentials.VerifyEmail','ForgotCredentials.UserNamesSuccessMessage', true);
        } else if (recoverUsernamesResult.isUserLocked) {          
          this.showMessage('ForgotCredentials.Error','ForgotCredentials.AccountLockedMessage', false);
        } else {          
          this.showMessage('ForgotCredentials.Error','ForgotCredentials.EmailSendFailureMessage', false);
        }
      } else {
        this.showMessage('ForgotCredentials.Error','ForgotCredentials.EmailSendFailureMessage', false);
        return;
      }
    });
  }  
  
  enableOrDisableComponent(element: any): void{
    let email = document.getElementById('email');
    let userId = document.getElementById('userId');
    let emailElement = <HTMLInputElement>email;
    let userIdElement = <HTMLInputElement>userId;
    
    if(element == 'email' && email["value"].length > 0){
      emailElement.disabled = false;
      userIdElement.disabled = true;
    }
    else if(element == 'userId' && userId["value"].length > 0) {
      emailElement.disabled = true;
      userIdElement.disabled = false;
    }
    else{
      emailElement.disabled = false;
      userIdElement.disabled = false;
    }
  }
}
